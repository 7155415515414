import React from 'react';

const Contact = () => {
  return (
    <section id="contact">
      <h2 class="neon-text">Get Connected</h2>
      <p>For bookings, collaborations, and tech talks:</p>
      <p>Email: <a href="/cdn-cgi/l/email-protection#46242327323506222c202f28282f2368272f"><span class="__cf_email__" data-cfemail="11737470656251757b77787f7f78743f7078">[email&#160;protected]</span></a></p>
      <p>Blockchain Comms: 0xDJFINNIE...8008</p>
    </section>
  );
};

export default Contact;
