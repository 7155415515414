import React from 'react';
//import MusicPlayer from '../components/MusicPlayer';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import About from '../components/About';
import Tracks from '../components/Tracks';
import Contact from '../components/Contact';
import Events from '../components/Events';
import Footer from '../components/Footer';
//import SocialLinks from '../components/SocialLinks';

const Home = () => {
  return (
  <>
    <header>
      <h1 className="neon-text">DJ FINNIE</h1>
      <div className="subtitle">Techno Innovator</div>
      <Navbar />
    </header>
    <main>
      <Hero />
      <div class="content">
        <About />
        <Tracks />
        <Events />
        <Contact />
      </div>
    </main>
    <Footer />
   </>
  );
};

export default Home;
