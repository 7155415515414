import React from 'react';
import { Nav, NavLink } from './styles';

const Navbar = () => {
  return (
    <Nav>
      <NavLink href="#home">Home</NavLink>
      <NavLink href="#about">About</NavLink>
      <NavLink href="#music">Music</NavLink>
      <NavLink href="#events">Events</NavLink>
      <NavLink href="#contact">Contact</NavLink>
    </Nav>
  );
};

export default Navbar;
