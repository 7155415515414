import styled from 'styled-components';

export const BgColor = "#0a0a0a";
export const TextColor = "#e0e0e0";
export const AccentColor = "#00ff99";
export const SecondaryColor = "#0a0a0a";
export const AnimatePulse = "animation: pulse 2s infinite";

export const Section = styled.div`
  margin-bottom: 40px;
`;

export const Title = styled.h1`
  font-size: 4em;
  margin: 0;
  text-shadow: 0 0 10px, color: ${AccentColor}, 0 0 20px, color: ${AccentColor}, 0 0 30px, color: ${AccentColor}
`;

export const Link = styled.a`
  color: #1e90ff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const Nav = styled.div`
  margin-top: 20px;
`;

export const NavLink = styled.a`
  color: ${TextColor};
  text-decoration: none;
  margin: 0 15px;
  font-size: 1.2em;
  transition: color 0.3s;
  text-transform: uppercase;
  &:hover {
    color: ${AccentColor};
  }
`;

export const RadioContainer = styled.div`
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  margin-top: 40px;
  border: 1px solid color: SecondaryColor;
`;

export const Player = styled.audio`
  width: 100%;
  margin-top: 10px;
`;

export const PlayPauseButton = styled.button`
  background: ${SecondaryColor};
  color: ${BgColor};
  border: none;
  padding: 5px 10px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 3px;
  cursor: pointer;
`;

export const TrackInfo = styled.div`
  margin-top: 10px;
  font-size: 14px;
`;

export const TrackList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const TrackItem = styled.li`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const HeroContainer = styled.div `
  background: url('https://websim.ai/images/dj-finnie.jpg') center/cover;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, rgba(0,255,153,0.2), rgba(255,0,222,0.2));
    z-index: 1;
  }
`;

export const HeroContent = styled.div `
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 40px;
  border-radius: 10px;
  z-index: 2;
  border: 2px solid ${AccentColor};
`;

export const HeroTitle = styled.h2 `
  font-size: 2.5em;
  margin-bottom: 20px;
  text-transform: uppercase;
`;

export const CTAButton = styled.button `
  display: inline-block;
  background: ${AccentColor};
  color: $BgColor;
  padding: 15px 30px;
  text-decoration: none;
  font-size: 1.2em;
  border-radius: 5px;
  transition: transform 0.3s, box-shadow 0.3s;
  text-transform: uppercase;
  font-weight: bold;
  ${AnimatePulse}
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 0 20px ${AccentColor};
  }
`;
