import React from 'react';

const Events = () => {
  return (
    <section id="events">
      <h2 class="neon-text">Upcoming Gigs</h2>
      <ul>
        <li>August 5 - TechnoLogic Festival, Silicon Valley</li>
        <li>September 18 - Cyberpunk Rave, Neo-Tokyo</li>
        <li>October 31 - Halloween Hackathon Afterparty, MIT</li>
      </ul>
    </section>
  );
};

export default Events;
