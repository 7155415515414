import React from 'react';
import { HeroContainer, HeroContent, HeroTitle, CTAButton } from './styles';

const About = () => {
  return (
    <section id="about">
      <h2 class="neon-text">About DJ FINNIE</h2>
      <p>DJ FINNIE is at the forefront of techno innovation, blending cutting-edge technology with raw musical talent. With a background in computer science and a passion for electronic music, FINNIE creates unique soundscapes that push the boundaries of what's possible in techno.</p>
    </section>
  );
};

export default About;
