import React from 'react';

const Tracks = () => {
  return (
    <section id="music" className="music-player">
      <h3>Latest Tracks</h3>
      <ul className="track-list">
        <li><button className="play-button">▶</button> Algorithmic Euphoria (AI Collab Mix)</li>
        <li><button className="play-button">▶</button> Quantum Bass Drop (feat. Schrödinger's Cat)</li>
        <li><button className="play-button">▶</button> Neural Network Nightlife (Extended Techno Remix)</li>
      </ul>
    </section>
  );
};

export default Tracks;
