import React from 'react';
import { HeroContainer, HeroContent, HeroTitle, CTAButton } from './styles';

const Hero = () => {
  return (
    <HeroContainer id="home">
      <HeroContent>
        <HeroTitle className="neon-text">Revolutionizing Techno</HeroTitle>
        <p>Experience the future of electronic music</p>
        <CTAButton href="#events" >Join the Revolution</CTAButton>
      </HeroContent>
    </HeroContainer>
  );
};

export default Hero;
